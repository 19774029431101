// ** React Imports
import { useEffect, useState, useMemo } from 'react'

// ** Third Party Components
import { NavLink } from 'react-router-dom'

// icons
import { Badge } from 'reactstrap'
import {
	Activity,
	Briefcase,
	Calendar,
	ChevronDown,
	ChevronUp,
	CreditCard,
	FileText,
	List,
	LogOut,
	Plus,
	Settings,
	Share2,
	Star,
	Table,
	User,
	X,
} from 'react-feather'
import useAuthStore from '@/stores/auth-store'
import useCompaniesStore from '@/stores/companies-store'
import ActiveCompanySelector from './ActiveCompanySelector'
import usePermissionsStore, { Permissions } from '@/stores/permissions-store'
// import ReferralProgramCard from './ReferralProgramCard'
import classNames from 'classnames'
import logoWasabilImg from '../logo-wasabil.webp'
import './style.scss'
import useLayoutStore from '@/stores/layout-store'
import useRouteStore from '@/stores/route-store'

export default function Sidebar() {
	const activeCompany = useCompaniesStore((state) => state.activeCompany)
	const userCan = usePermissionsStore((state) => state.userCan)
	const [sidebarOpen, setSidebarOpen] = useLayoutStore((state) => [state.sidebarOpen, state.setSidebarOpen])
	const close = () => setSidebarOpen(false)
	const route = useRouteStore((state) => state.route)

	const purchaseInvoiceDisabled = activeCompany?.issuer_purchase_status == 'disabled'
	const saleInvoiceDisabled = activeCompany?.issuer_sale_status == 'disabled'
	const saleReceiptDisabled = activeCompany?.issuer_sale_receipt_status == 'disabled'
	let initialExpanded = new Set()
	if (!purchaseInvoiceDisabled) initialExpanded.add('purchases')
	if (!saleInvoiceDisabled || !saleReceiptDisabled) initialExpanded.add('sales')

	const [expanded, setExpanded] = useState(initialExpanded)
	const toggleExpanded = (key) => {
		const newExpanded = new Set(expanded)
		if (newExpanded.has(key)) newExpanded.delete(key)
		else newExpanded.add(key)
		setExpanded(newExpanded)
	}

	const items = [
		{
			title: 'Inicio',
			icon: Activity,
			navLink: { to: '/dashboard' },
			show: Boolean(activeCompany),
		},
		{
			title: 'Documentos',
			icon: FileText,
			navLink: { to: '/documents' },
			show: Boolean(activeCompany),
		},
		// VENTAS
		{
			header: 'Ventas',
			expanded: expanded.has('sales'),
			onClick: () => toggleExpanded('sales'),
			show: Boolean(activeCompany),
		},
		{
			title: 'Emitir Factura',
			icon: Plus,
			navLink: { to: '/sale-invoice/issue' },
			disabled: saleInvoiceDisabled,
			show: Boolean(activeCompany && expanded.has('sales') && userCan(Permissions.ISSUE_DOCUMENTS)),
		},
		{
			title: 'Programar Factura',
			icon: Calendar,
			navLink: { to: '/sale-invoice/programmed' },
			disabled: saleInvoiceDisabled,
			show: Boolean(
				activeCompany && expanded.has('sales') && userCan(Permissions.EDIT_PROGRAMMED_DOCUMENTS)
			),
		},
		{
			title: 'Emitir Boleta',
			icon: Plus,
			navLink: { to: '/sale-receipt/issue' },
			disabled: saleReceiptDisabled,
			show: Boolean(activeCompany && expanded.has('sales') && userCan(Permissions.ISSUE_DOCUMENTS)),
		},
		{
			title: 'Integraciones E-commerce',
			icon: Share2,
			navLink: { to: '/sales-integrations' },
			disabled: saleReceiptDisabled,
			show: Boolean(activeCompany && expanded.has('sales') && userCan(Permissions.EDIT_INTEGRATIONS)),
		},
		{
			title: 'Listado de Clientes',
			icon: List,
			navLink: { to: '/clients' },
			show: Boolean(activeCompany && expanded.has('sales')),
		},
		// COMPRAS ----------------------
		{
			header: 'Gastos en el extranjero',
			expanded: expanded.has('purchases'),
			onClick: () => toggleExpanded('purchases'),
			show: Boolean(activeCompany),
		},
		{
			title: 'Emitir Factura de Compra',
			icon: Plus,
			navLink: { to: '/purchase-invoice/issue' },
			disabled: purchaseInvoiceDisabled,
			show: Boolean(activeCompany && expanded.has('purchases') && userCan(Permissions.ISSUE_DOCUMENTS)),
		},
		{
			title: 'Programar Factura de Compra',
			icon: Calendar,
			navLink: { to: '/purchase-invoice/programmed' },
			disabled: purchaseInvoiceDisabled,
			show: Boolean(
				activeCompany && expanded.has('purchases') && userCan(Permissions.EDIT_PROGRAMMED_DOCUMENTS)
			),
		},
		{
			title: 'Emitir desde Archivo',
			icon: Table,
			navLink: { to: '/purchase-invoice/bulk' },
			disabled: purchaseInvoiceDisabled,
			show: Boolean(activeCompany && expanded.has('purchases') && userCan(Permissions.ISSUE_DOCUMENTS)),
		},

		{
			title: 'Integraciones',
			icon: Share2,
			navLink: { to: '/suppliers-integrations' },
			disabled: purchaseInvoiceDisabled,
			show: Boolean(activeCompany && expanded.has('purchases') && userCan(Permissions.EDIT_INTEGRATIONS)),
		},
		{
			title: 'Listado de Proveedores',
			icon: List,
			navLink: { to: '/suppliers' },
			show: Boolean(activeCompany && expanded.has('purchases')),
		},
		{
			header: 'Configuración',
		},
		{
			title: 'Empresa',
			icon: Briefcase,
			navLink: { to: '/companies' },
		},
		{
			title: 'Facturador',
			icon: Settings,
			navLink: { to: '/companies/issuer' },
			show: Boolean(activeCompany && userCan(Permissions.COMPANY_ADMIN)),
		},
		{
			title: 'Plan de Suscripción',
			icon: CreditCard,
			navLink: { to: '/plan' },
			show: Boolean(activeCompany && userCan(Permissions.COMPANY_ADMIN)),
		},
		{
			title: 'Programa de Referidos',
			icon: Star,
			navLink: { to: '/referral-program' },
			show: Boolean(activeCompany),
		},
		{
			title: 'Usuario',
			icon: User,
			navLink: { to: '/account' },
		},
		{
			title: 'Cerrar sesión',
			icon: LogOut,
			onClick: () => useAuthStore.getState().logout(),
			navLink: { isActive: () => false, to: '#' },
		},
	].filter((x) => x.show !== false)

	useEffect(() => {
		close()
	}, [route])

	return (
		<div className={classNames(`app-sidebar`, { '--open': sidebarOpen })}>
			<div className="__drawer main-menu menu-light">
				<div className="d-flex align-items-center px-1 py-50">
					<NavLink to="/dashboard" className="__logo" onClick={close}>
						<img src={logoWasabilImg} alt="Wasabil" />
					</NavLink>
					<div className="spacer"></div>
					<X size={30} color="#777" onClick={() => setSidebarOpen(false)} className="__close-x" />
				</div>
				<ActiveCompanySelector />

				<ul className="navigation navigation-main">
					{items.filter(Boolean).map((item, i) => {
						if (item.divider) {
							return (
								<div className={classNames({ 'px-2': item.withPadding })} key={i}>
									<hr className="w-100" />
								</div>
							)
						} else if (item.header) {
							return (
								<li
									className={classNames(`navigation-header`, {
										'navigation-header--expanded': item.expanded,
									})}
									key={i}
									onClick={() => item.onClick?.()}
								>
									<span className="d-flex align-items-center">
										<div>{item.header}</div>
										<div className="spacer"></div>
										{item.expanded !== undefined &&
											(item.expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />)}
									</span>
								</li>
							)
						} else if (item.subheader) {
							return (
								<li
									className={classNames(`navigation-subheader`, {
										'navigation-subheader--expanded': item.expanded,
									})}
									key={i}
									onClick={() => item.onClick?.()}
								>
									<span className="d-flex align-items-center">
										{item.subheader}
										<div className="spacer"></div>
										{item.expanded !== undefined &&
											(item.expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />)}
									</span>
								</li>
							)
						} else if (item.custom) {
							return item.custom(i)
						} else {
							return (
								<li
									key={i}
									className={classNames({
										'nav-item': !item.children,
										disabled: item.disabled,
										active: false, // item.navLink == window.location.pathname,
									})}
								>
									<NavLink
										className="d-flex align-items-center"
										onClick={() => {
											close()
											item.onClick?.()
										}}
										{...{ exact: true, to: 0, ...item.navLink }}
									>
										<item.icon size={16} className="mr-1" />

										<span className="menu-item text-truncate">{item.title}</span>

										{!!item.badge && !!item.badgeText && (
											<Badge className="ml-auto mr-1" color={item.badge} pill>
												{item.badgeText}
											</Badge>
										)}
									</NavLink>
								</li>
							)
						}
					})}
				</ul>
			</div>
			<div className="__overlay" onClick={() => setSidebarOpen(false)}></div>
		</div>
	)
}

